import React from 'react'
import LayoutStatic from '../components/LayoutStatic'
import { injectIntl, Link } from 'gatsby-plugin-intl'
import { graphql } from 'gatsby'

class FaqList extends React.Component {

  getTextFromIntl = (key) => {
    return this.props.intl.formatMessage({ id: key })
  }

  render() {

    const { currentPage, numPages, categorySlug } = this.props.pageContext

    const category = this.props.data.site.siteMetadata.postCategories
    const categoryForLocale = category[categorySlug][this.props.intl.locale]
    const categoryCommon = category[categorySlug].common

    const locale = this.props.intl.locale
    const isJP = locale === 'ja'

    const paged = currentPage
		const max_page = numPages
		let pstart = paged - 3
		let pend = paged + 3
		if (pstart < 1) pstart = 1
		if (pend > max_page) pend = max_page

    const pages = []
    for (let i = pstart; i <= pend; i++) {
      pages.push(i)
    }
  
    return (
        <LayoutStatic {...this.props} title="FAQ">          
        { categoryForLocale.length != 0 ? 
          <h2><span title={categoryForLocale}>{categoryCommon}</span></h2>
          : 
          <h2>{categoryCommon}</h2>
        }
        { isJP ? 
          <p className="description">FAQを見ても問題が解決しない場合は、サポートへ<Link to="/support">お問い合わせ</Link>ください。</p>
          :
        	<p class="description">If you still have a problem after viewing this FAQ, please <Link to="/support">contact us.</Link></p>
        }
      	<ul class="faq">
        { this.props.data.allMarkdownRemark.edges.map(({ node }, index) => (
          <li key={index}><Link to={node.frontmatter.path}>{node.frontmatter.title[locale]}</Link></li>
        ))}
        </ul>
        </LayoutStatic>
    )
  }
}

export const faqListQuery = graphql`
  query faqListQuery($skip: Int!, $limit: Int!, $categorySlug: String!) {
    site {
      siteMetadata {
        postCategories {
          news {
            common
            ja
            en
          }
          faq {
            common
            ja
            en
          }
        }
      }
    }
    allMarkdownRemark(
      filter: {frontmatter: { category : {eq: $categorySlug}}} 
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      totalCount
      edges {
        node {
          html
          frontmatter {
            title {
              ja
              en
            }
            path
            date
            category
          }
        }
      }
    }
  }
`

export default injectIntl(FaqList)

